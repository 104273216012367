import React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Resources = () => (
  <Layout>
    <Seo title="Trending" />

    <section className="h-screen py-6 dark:bg-coolGray-800 dark:text-coolGray-50 flex ">
      <div className="container mx-auto lg:flex flex-col items-center justify-center p-4 space-y-8 md:p-10 md:px-24 xl:px-48 ">
        <h1 className="text-5xl text-[#33c3b0] leading-none text-center">
          Coming soon
        </h1>
        <p className="text-xl  text-center text-gray-500">
          We are working tirelessly to bring more features every week.
        </p>

        <nav
          aria-label="breadcrumb"
          className="w-full p-4 dark:bg-coolGray-800 dark:text-coolGray-100 flex flex-col items-center justify-center"
        >
          <ol className="flex h-8 space-x-2 dark:text-coolGray-100">
            <li className="flex items-center">
              <a
                rel="noopener noreferrer"
                href="/"
                title="Back to homepage"
                className="flex items-center hover:underline"
              >
                Home
              </a>
            </li>
            <li className="flex items-center space-x-1">
              <span className="dark:text-coolGray-400">/</span>
              <a
                rel="noopener noreferrer"
                href="/know"
                className="flex items-center px-1 capitalize hover:underline"
              >
                About
              </a>
            </li>
            <li className="flex items-center space-x-1">
              <span className="dark:text-coolGray-400">/</span>
              <a
                rel="noopener noreferrer"
                href="/started"
                className="flex items-center px-1 capitalize hover:underline font-bold "
              >
                Features
              </a>
            </li>
            <li className="flex items-center space-x-1">
              <span className="dark:text-coolGray-400">/</span>
              <a
                rel="noopener noreferrer"
                href="/contact"
                className="flex items-center px-1 capitalize hover:underline"
              >
                Contact
              </a>
            </li>
          </ol>
          <footer>
            © {new Date().getFullYear()}, An Idea by
            {` `}
            <a className="text-center text-sm" href="https://www.chakama.co.zw">
              George Chakama
            </a>
          </footer>
        </nav>
      </div>
    </section>

    {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <StaticImage
        src="../images/gatsby-astronaut.png"
        alt="gatsby astronaut"
      />
    </div>
    <p>
      <Link to="/page-2/">Go to page 2</Link>
    </p>
    <p>
      <Link to="/using-typescript/">Go to "Using TypeScript"</Link>
    </p> */}
  </Layout>
)

export default Resources
